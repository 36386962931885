

















































import { apiThemeConfig, apiThemeConfigSet } from '@/api/shop'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class ShopTheme extends Vue {
    /** S data **/
    theme = 'red_theme'
    themeList = [
        {
            name: 'red_theme',
            title: '经典红'
        },
        {
            name: 'orange_theme',
            title: '活力橙'
        },
        {
            name: 'pink_theme',
            title: '美妆色'
        },
        {
            name: 'gold_theme',
            title: '高级金'
        },
        {
            name: 'blue_theme',
            title: '科技蓝'
        },
        {
            name: 'green_theme',
            title: '生鲜绿'
        }
    ]
    /** E data **/

    /** S methods **/
    getThemeConfig() {
        apiThemeConfig({ type: 1 }).then(res => {
            if (res.theme) {
                this.theme = res.theme
            }
        })
    }
    handleSave() {
        apiThemeConfigSet({
            type: 1,
            content: {
                theme: this.theme
            }
        })
    }
    /** E methods **/

    /** S life cycle **/
    created() {
        this.getThemeConfig()
    }
    /** E life cycle **/
}
