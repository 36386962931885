import { render, staticRenderFns } from "./theme.vue?vue&type=template&id=c79e8962&scoped=true&"
import script from "./theme.vue?vue&type=script&lang=ts&"
export * from "./theme.vue?vue&type=script&lang=ts&"
import style0 from "./theme.vue?vue&type=style&index=0&id=c79e8962&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c79e8962",
  null
  
)

export default component.exports